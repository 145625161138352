
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private active = 0;
  private changeType = true;
  private show = false;
  private show1 = false;
  private listData = [];
  private quality: any[] = [];
  private sjjdetail = { itemList: [] as any };
  private elevatorDetail1 = {};
  private elevatorDetail2 = {};
  private elevatorDetail: any = {
    elevatorLoad: "",
    angle: "",
    numberOfPeople: "",
    speed: "",
    height: "",
  };
  private searchForm = {
    dirId: "",
    level: null,
    // deviceTime: parseTime(new Date(), "{y}-{m}-{d}"),
    deviceTime: "",
    deviceNo: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };

  mounted() {
    this.sjjdetail = this.$store.state.project.sjjdetail;
    if (this.sjjdetail.itemList.length > 2) {
      this.sjjdetail.itemList.splice(0, 2);
    }
    if (this.sjjdetail.itemList.length === 1) {
      api
        .listQualificationByNo({
          projectId: this.$store.state.project.projectinfoDetail.id,
          no: this.sjjdetail.itemList[0].driverNo,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.sjjdetail.itemList[0].zhengshu = res.data;
          } else {
            this.sjjdetail.itemList[0].zhengshu = [];
          }
        });
    }
    if (this.sjjdetail.itemList.length === 2) {
      api
        .listQualificationByNo({
          projectId: this.$store.state.project.projectinfoDetail.id,
          no: this.sjjdetail.itemList[0].driverNo,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.sjjdetail.itemList[0].zhengshu = res.data;
          } else {
            this.sjjdetail.itemList[0].zhengshu = [];
          }
        });
      api
        .listQualificationByNo({
          projectId: this.$store.state.project.projectinfoDetail.id,
          no: this.sjjdetail.itemList[1].driverNo,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.sjjdetail.itemList[1].zhengshu = res.data;
          } else {
            this.sjjdetail.itemList[1].zhengshu = [];
          }
        });
    }
    this.getDetailById();
  }
  checkZh(item: any) {
    this.quality = item.zhengshu;
    this.show = true;
  }
  rlHistory(item: any) {
    this.$router.push({ path: "/app/rlhistory", query: { id: item.deviceNo } });
  }
  changeStyle() {
    this.show1 = true;
    this.changeType = !this.changeType;
    this.elevatorDetail = {
      elevatorLoad: "",
      angle: "",
      numberOfPeople: "",
      speed: "",
      height: "",
    };
    setTimeout(() => {
      if (this.changeType) {
        this.elevatorDetail = this.elevatorDetail1;
      } else {
        this.elevatorDetail = this.elevatorDetail2;
      }
      this.searchForm.deviceNo = this.elevatorDetail.deviceNo;
      this.getGaoJingData();
      this.show1 = false;
    }, 1000);
  }
  getDetailById() {
    api
      .getDetailById(this.$store.state.project.sjjdetail.id)
      .then((res: any) => {
        if (res.data.elevatorItemList.length === 1) {
          this.elevatorDetail1 = res.data.elevatorItemList[0];
        } else if (res.data.elevatorItemList.length > 2) {
          res.data.elevatorItemList.splice(0, 2);
          this.elevatorDetail1 = res.data.elevatorItemList[0];
          this.elevatorDetail2 = res.data.elevatorItemList[1];
        } else if (res.data.elevatorItemList.length === 2) {
          this.elevatorDetail1 = res.data.elevatorItemList[0];
          this.elevatorDetail2 = res.data.elevatorItemList[1];
        }
        this.elevatorDetail = this.elevatorDetail1;
        this.searchForm.deviceNo = this.elevatorDetail.deviceNo;
        this.getGaoJingData();
      });
  }
  getGaoJingData() {
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
    api.pageElevatorAlertRecord(this.searchForm).then((res: any) => {
      if (res.data.records.length > 3) {
        res.data.records = res.data.records.splice(0, 3);
        this.listData = res.data.records;
      }
      this.listData = res.data.records;
    });
  }
  morejianceData() {
    this.$router.push({
      path: "/app/jiancedetail",
      query: { id: this.elevatorDetail.deviceNo },
    });
  }
  moreGaojingData() {
    this.$router.push({
      path: "/app/gaojingdetail",
      query: { id: this.elevatorDetail.deviceNo },
    });
  }
}
